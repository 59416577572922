<template>
  <svg
    id="536c92c5-ab85-4289-ba61-f691f9ece623"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"><path
      d="M7.38,0H29.63A7.37,7.37,0,0,1,37,7.37V29.62A7.37,7.37,0,0,1,29.63,37H7.37A7.37,7.37,0,0,1,0,29.62V7.38A7.38,7.38,0,0,1,7.38,0Z"
      class="icon-wrapper__template"
      :fill="color" /><path
        d="M30.42,28.71h-2.5V7.52a.45.45,0,0,0-.44-.45A10.68,10.68,0,0,0,19,10.82a.45
        .45,0,0,0-.12.31V28.71H18V7.52a.45.45,0,0,0-.76-.33,11.87,11.87,0,0,1-7.82,
        3.49.45.45,0,0,0-.44.45V28.71H6.63a.45.45,0,0,0,0,.9H30.42
        a.45.45,0,0,0,0-.9Zm-18.93,0V24.2h1.56v4.51Zm2.46,0V24.2h1.56v4.51Zm2.46,0v-5A.45.45,0,0,0
        ,16,23.3H11a.45.45,0,0,0-.45.45v5H9.9V11.56a12.45,12.45,0,0,0,7.21-3V28.71Zm5,
        0V24.2H23v4.51Zm2.46,0V24.2h1.56v4.51Zm2.46,0v-5a.45.45,0,0,0-.45-.45H21a.45.45
        ,0,0,0-.45.45v5h-.69V11.31A9.8,9.8,0,0,1,27,8V28.71Z"
        transform="translate(-0.03 0.01)"
        fill="#fff" /><path
          d="M15.95,19.69H11.08a.46.46,0,0,0-.47.45V22a.46.46,0,0,0,.47.45h4.87a.46.46,0,0,0,.47-.45V20.14A.46.46,0,0,0,15.95,19.69Zm-.47,1.84H11.55v-.94h3.93Z"
          transform="translate(-0.03 0.01)"
          fill="#fff" /><path
            d="M15.95,16.09H11.08a.46.46,0,0,0-.47.45v1.84a.46.46,0,0,0,.47.45h4.87a.46.46,0,0,0,.47-.45V16.54A.46.46,0,0,0,15.95,16.09Zm-.47,1.84H11.55V17h3.93Z"
            transform="translate(-0.03 0.01)"
            fill="#fff" /><path
              d="M15.95,12.48H11.08a.46.46,0,0,0-.47.45v1.84a.46.46,0,0,0,.47.45h4.87a.46.46,0,0,0,.47-.45V12.93A.46.46,0,0,0,15.95,12.48Zm-.47,1.84H11.55v-.94h3.93Z"
              transform="translate(-0.03 0.01)"
              fill="#fff" /><path
                d="M25.87,19.51H21a.46.46,0,0,0-.47.45V21.8a.46.46,0,0,0,.47.45h4.87a.46.46,0,0,0,.47-.45V20A.46.46,0,0,0,25.87,19.51Zm-.47,1.84H21.47v-.94H25.4Z"
                transform="translate(-0.03 0.01)"
                fill="#fff" /><path
                  d="M25.87,15.9H21a.46.46,0,0,0-.47.45v1.84a.46.46,0,0,0,.47.45h4.87a.46.46,0,0,0,.47-.45V16.35A.46.46,0,0,0,25.87,15.9Zm-.47,1.84H21.47v-.94H25.4Z"
                  transform="translate(-0.03 0.01)"
                  fill="#fff" /><path
                    d="M25.87,12.3H21a.46.46,0,0,0-.47.45v1.84A.46.46,0,0,0,21,15h4.87a.46.46,0,0,0,.47-.45V12.75A.46.46,0,0,0,25.87,12.3Zm-.47,1.84H21.47V13.2H25.4Z"
                    transform="translate(-0.03 0.01)"
                    fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>